$fleming-color: #262958;
$fleming-light-color: #3f458e;

* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: "Open Sans", sans-serif;
	font-size: 16px;
}

a {
	text-decoration: none;
}

.header {
	background-color: $fleming-color;

	.navbar-toggler {
		margin-right: -15px !important;
		border: none;
		outline: 0;
	}
}

h1 {
	background-color: $fleming-light-color;
	text-align: left;
	color: rgba(255, 255, 255, 0.85);
	font-size: 1.5em !important;
	padding: 10px;
	padding-left: 30px;
	font-weight: 200 !important;
	margin-bottom: 0px !important;
}

.main-container {
	background-color: #f4f4f4;
	padding-top: 30px;
	padding-bottom: 30px;
	min-height: calc(100vh - 56px - 39px);
}

.main-container-login {
	background-color: #f4f4f4;
	padding-top: 30px;
	padding-bottom: 30px;
	min-height: calc(100vh);
}

.main-frame {
	max-width: 600px;
	margin: auto;
	border-radius: 4px;
	background-color: white;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}

.login-form {
	padding: 20px;
}

.login-button {
	background-color: $fleming-light-color;
	color: white;
	text-align: center;
	padding: 10px 20px;
	border-radius: 5px;
	margin: auto;
	margin-top: 20px;
    border: none;

    &:hover {
        cursor: pointer;
        background-color: #2e325f;
    }
}


.login-form-input {
	text-transform: capitalize;
}

.dropdown-item {
	outline: 0 !important;

	a {
		color: $fleming-color !important;
	}
}

.dropdown-item a:hover {
	color: $fleming-color !important;
	text-decoration: none !important;
}

.dropdown-item.active,
.dropdown-item:active {
	background-color: #f4f4f4 !important;
	color: black;
}

.loading {
	display: flex;
	background-color: #f4f4f4;
	justify-content: center;
	align-items: center;
	height: calc(100vh - 56px - 39px);
}

.no-data {
	display: flex;
	background-color: #f4f4f4;
	justify-content: center;
	align-items: center;
	height: calc(100vh - 56px - 39px);
}

@media (max-width: 600px) {
	h1 {
		text-align: center;
		padding-left: 0px;
	}

	.main-frame {
		width: 90%;
	}

	.login-form .form-group {
		margin-bottom: 0;
	}
}

.main-content {
	min-height: calc(100vh - 56px - 39px);
}

// =====================

.footer {
	color: white;
	background-color: #343a40;
	padding: 10px;
	text-align: right;
	font-size: 0.8em;
}

.release {
	font-size: 0.8em;
}

//==

.date-cleaning {
	font-size: 1em;
	font-weight: 100;
}

.date-cleaning:first-letter {
	text-transform: uppercase;
}

.group {
	font-weight: 700;
	font-size: 1.2em;
}

//====

.accordion .accordion-item .title:after {
	content: "" !important;
	height: 16px;
	width: 16px;
	background: url("https://static.thenounproject.com/png/1245236-200.png") no-repeat 0 0;
	background-size: 16px 16px;
}

.accordion .accordion-item.active .panel {
	max-height: 1000px !important;
}

.accordion .accordion-item.active .title:after {
	background: none;
}

.group-container {
	padding-top: 20px;
	padding-bottom: 20px;
}

.group-container .precursor:after {
	font-size: 0.8em;
	color: #999;
	content: " (PR)";
}

.group-container .encargado {
	color: $fleming-color;
}

.group-container .encargado:before {
	content: "Encargado: ";
	font-weight: 500;
}

.group-container .auxiliar {
	font-style: italic;
	color: $fleming-color;
	padding-bottom: 10px;
}

.group-container .auxiliar:before {
	content: "Auxiliar: ";
	font-weight: 500;
}

.group-item {
	padding-left: 20px;
	font-size: 0.9em;
}

.group-item:before {
	content: "• ";
}

.no-visit {
	padding-left: 20px;
	font-size: 0.9em;
}

.no-visit:before {
	content: "• ";
}

//====

ul {
	padding: 10px;
}

.service-container li {
	padding-bottom: 20px;
}

.hora {
	font-weight: 700;
}

.salida {
	font-size: 0.9em;
	margin-left: 10px;
}

.nota {
	font-weight: bold;
	margin: 10px 0;
}

.grupos {
	font-weight: 700;
}

.conductor {
	padding-top: 5px;
	color: #666;
	font-size: 0.9em;
}

.territorio {
    display: block;
	color: green;
	font-weight: 700;
	padding: 5px;
}

.telefono {
	white-space: nowrap;
	color: $fleming-light-color;
}

.telefono:hover {
	color: $fleming-light-color;
}

// ======

.territory-image {
	width: 100%;
	height: 100%;
}

.territory-badge {
	width: 60px;
	cursor: pointer;
	margin-left: 5px;
    background-color: #3f458e;
    color: white;
    font-weight: bold;
    border: none;
    border-radius: 10px;
    margin: 10px;
}

.territory-button-np {
	background-color: $fleming-light-color;
	color: white;
	margin-top: 1em;
	margin-bottom: 1em;
	display: inline-block;
	padding: 5px 10px;
	border-radius: 5px;
}

.territory-button-np:hover {
	cursor: pointer;
	background-color: #2e325f;
}

// ====
.video-container {
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 30px;
	height: 0;
	overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

// ====

.block-container {
	margin-left: 30px;
	margin-right: 30px;
	padding-top: 20px;
	padding-bottom: 20px;
    margin-bottom: 10px;
	border-bottom: 1px dotted #b2b0af;

    @media(max-width: 768px){
        margin-left: 20px;
        margin-right: 10px;
    }
}

.date {
	font-size: 1.1em;
	font-weight: 600;
	padding-bottom: 5px;
}

.date:first-letter {
	text-transform: uppercase;
}

.speacher {
	color: #858585;
	font-size: 0.9em;
	display: inline;
}

.congregation {
	color: #858585;
	font-size: 0.8em;
	padding-left: 10px;
}

.titulo {
	font-size: 1.1em;
	font-weight: 600;
	margin-top: 15px;
    text-transform: uppercase;
}

button.close{
    border: none;
    background: none;
}

ul, li{
    padding: 0;
    margin: 0;
}
